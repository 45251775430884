<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.patient_service") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <table
      class="table table-bordered table-hover py-2 my-5 mx-2 mr-5"
      v-loading="loadingData"
    >
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <crm-th :column="columns.order_id" :sort="sort" @c-change="updateSort">
          {{ $t("message.order_id") }}
        </crm-th>
        <crm-th
          :column="columns.patient_id"
          :sort="sort"
          @c-change="updateSort"
          >{{ $t("message.patient_id") }}</crm-th
        >
        <crm-th :column="columns.patient" :sort="sort" @c-change="updateSort">{{
          $t("message.patient")
        }}</crm-th>
        <crm-th :column="columns.service" :sort="sort" @c-change="updateSort">{{
          $t("message.service")
        }}</crm-th>
        <crm-th
          :column="columns.lifetime"
          :sort="sort"
          @c-change="updateSort"
          >{{ $t("message.lifetime") }}</crm-th
        >
        <crm-th
          :column="columns.condition"
          :sort="sort"
          @c-change="updateSort"
          >{{ $t("message.condition") }}</crm-th
        >
        <crm-th
          :column="columns.order_created_at"
          :sort="sort"
          @c-change="updateSort"
          >{{ $t("message.order_created_at") }}</crm-th
        >
        <crm-th
          :column="columns.order_updated_at"
          :sort="sort"
          @c-change="updateSort"
          >{{ $t("message.order_updated_at") }}</crm-th
        >
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="(order, index) in list" class="cursor-pointer" :key="index">
          <td>{{ order.order.id }}</td>
          <td>{{ order.patient.id }}</td>
          <td>
            {{
              order.patient
                ? order.patient.surname + " " + order.patient.first_name
                : ""
            }}
          </td>
          <td>{{ order.service.name }}</td>
          <td>{{ order.lifetime }}</td>
          <td>
            {{ order.order.step ? $t("message.status_" + order.order.step) : "" }}
          </td>
          <td>{{ order.created_at }}</td>
          <td>{{ order.updated_at }}</td>
        </tr>
      </transition-group>
    </table>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
export default {
  mixins: [form, drawerChild],
  props: ["drawer", "open", "order"],
  data() {
    return {
      waiting: false,
      form: {},
      tablePatientData: [],
      tableServicesData: [],
      loadingData: false,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      list: "orders/expiredList",
      model: "orders/relation_model",
      sort: "orders/expired_sort",
      pagination: "orders/expired_pagination",
      columns: "orders/expired_columns",
    }),
  },
  watch: {
    "pagination.page": {
      handler: async function (newVal, oldVal) {
        await this.fetchData();
      },
    },
    "pagination.per_page": {
      handler: async function (newVal, oldVal) {
        await this.fetchData();
      },
    },
    sort: {
      handler: async function (newVal, oldVal) {
        await this.fetchData();
      },
    },
  },
  methods: {
    ...mapActions({
      updateSort: "orders/updateExpiredSort",
      updatePagination: "orders/updateExpiredPagination",
      loadData: "orders/expiredService",
    }),
    async fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      this.loadingData = true;
      this.loadData(query)
        .then((res) => {
          this.loadingData = false;
        })
        .catch((res) => {
          this.loadingData = false;
        });
    },
    afterOpened() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      this.loadingData = true;
      this.loadData(query)
        .then((res) => {
          this.loadingData = false;
        })
        .catch((res) => {
          this.loadingData = false;
        });
    },
    loadOrder() {
      const query = { id: this.order.id, relation: true };
      this.waiting = true;
      this.show(query)
        .then((res) => {
          this.loadModel();
          this.waiting = false;
        })
        .catch((err) => {
          this.waiting = false;
        });
    },
    loadModel() {
      this.tablePatientData = [JSON.parse(JSON.stringify(this.model.patient))];
      this.tableServicesData = JSON.parse(
        JSON.stringify(this.model.order_services)
      );
    },
    close() {
      this.$emit("c-close", { drawer: "drawerOrderShow" });
    },
    parent() {
      return this.$parent.$parent;
    },
  },
};
</script>